<template>
  <div
    class="w-full h-full group/cell flex items-center "
  >
    <LabelListPopover
      ref="labelRef"
      source="board"
      heading="Add labels"
      :selected-ids="map(filterdLabels, 'id')"
      :parent-id="task.boardId"
      :disabled="disabled"
      :task-id="task.id"
      class="w-full h-full flex items-center"
      :ui="{ width: 'w-[280px]', trigger: `flex ${disabled ? '!cursor-not-allowed' : ''}` }"
      @select="onToggleLabels"
      @remove="onToggleLabels"
    >
      <ResizeLabels
        :labels="filterdLabels" :get-label-style="getColorPreset"
        @click="emit('click')"
      />
    </LabelListPopover>
  </div>
</template>

<script lang="ts" setup>
import { map } from 'lodash-es'
import type { TaskItem } from '#task/types'
import type { Label } from '#label/types'

const props = defineProps({
  task: {
    type: Object as PropType<TaskItem>,
    required: true,
  },
  width: {
    type: Number,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const { toggleLabels } = useUpdateTask()

const onToggleLabels = (label: Label) => toggleLabels({ taskIds: [props.task.id], detachLabelIds: [], labelIds: [label.id] })
const emit = defineEmits(['set-callback', 'click'])
const { checkedTasks } = useListViewSharedState()

const labelRef = ref()

const filterdLabels = computed(() => props.task.labels?.filter((label) => label.id))

onMounted(() => {
  emit('set-callback', {
    open: () => labelRef.value?.open(),
    delete: () => {
      if (checkedTasks.value.has(props.task.id)) {
        toggleLabels({ taskIds: Array.from(checkedTasks.value), detachLabelIds: [], labelIds: map(filterdLabels.value, 'id') })
      }

      toggleLabels({
        taskIds: [props.task.id],
        detachLabelIds: [],
        labelIds: map(filterdLabels.value, 'id'),
      })
    }
  })
})
</script>
